import CertGenNavigation from '@components/CertGen/CertGenNavigation/index';
import CertGenShareDetails from '@components/CertGen/CertGenShareDetails/index';
import SEO from '@components/Common/SEO/index';
import React from 'react';

export default function ShareCertificate() {
  return (
    <>
      <SEO title="Certificate Share"></SEO>
      <CertGenNavigation />
      <CertGenShareDetails />
    </>
  );
}
