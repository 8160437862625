import React, { useEffect, useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import strapiDb from '@src/data/db.json';
import RawHTML from '@components/Common/RawHTML/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';

import { Helmet } from 'react-helmet';

export default function CertGenShareDetails() {
  const externalCertificates = strapiDb.strapi_metadata?.externalCertificates;
  const location = useLocation();
  const [redirectUri, setRedirectUri] = useState('');
  const [code, setCode] = useState(null);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [email, setEmail] = useState(null);
  const [savedContent, setSavedContent] = useState('');
  const [certificateUrl, setCertificateUrl] = useState('');
  const contentRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const [rightHeight, setRightHeight] = React.useState();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRedirectUri(`${window.location.origin}/certificate/share/`);
      const urlParams = new URLSearchParams(window.location.search);
      setCode(urlParams.get('code'));

      // Retrieve currentProgram and certificateImage from sessionStorage
      const storedProgram = sessionStorage.getItem('currentProgram');
      const storedImage = sessionStorage.getItem('certificateImage');
      const storedCertificateUrl = sessionStorage.getItem('certificateUrl');
      const email = sessionStorage.getItem('email');
      let caption = externalCertificates?.find(
        (certificate) => certificate.slug === storedProgram,
      )?.caption;

      caption = caption.replace('{$certificate_url$}', storedCertificateUrl);

      // Check if either currentProgram or certificateImage is not present
      if (!storedProgram || !storedImage) {
        navigate('/'); // Redirect to home page if either is missing
        return; // Exit early to prevent further execution
      }

      // Set state variables if both values are present
      setCurrentProgram(storedProgram);
      setCertificateUrl(storedCertificateUrl || '');
      setEmail(email);
      setImgUrl(storedImage);
      setSavedContent(caption);
    }
  }, []);

  useEffect(() => {
    updateRightHeight();
  }, [imgUrl, savedContent, code]);

  const updateRightHeight = () => {
    if (imgRef.current) {
      const leftHeight = imgRef.current.offsetHeight;
      setRightHeight(leftHeight);
    }
  };

  const handleContentChange = () => {
    if (contentRef.current) {
      sessionStorage.setItem(
        'editedCertificateContent',
        contentRef.current.innerText,
      );
      setSavedContent(contentRef.current.innerText);
    }
  };

  const downloadCertificate = () => {
    if (typeof window !== 'undefined' && imgUrl) {
      const link = document.createElement('a');
      link.href = imgUrl;
      link.download = 'certificate.jpg';
      link.click();
    }
  };

  const shareOnLinkedInV2 = () => {
    const postCaption = encodeURIComponent(savedContent);
    const postUrl = `https://www.linkedin.com/shareArticle/?mini=true&text=${postCaption}`;
    window.location.href = postUrl;
  };

  return (
    <div className="mx-6 mt-10 flex flex-col items-stretch gap-4 pb-2 md:flex-row md:gap-2">
      <Helmet>
        <meta property="og:url" content={''}></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          name="title"
          property="og:title"
          content={'Crio Certificate'}
        ></meta>
        {/* <meta property="og:description" content={'Certificate Link'}></meta> */}
        <meta name="image" property="og:image" content={certificateUrl}></meta>
      </Helmet>
      <div>
        <div className="text-sm text-v5-neutral-500">
          The following image will be attached with the post.
        </div>
        <img
          src={imgUrl}
          ref={imgRef}
          className="max-w-full rounded-md object-cover md:max-w-[65vw]"
          alt=""
        />
      </div>
      <div
        className="text-md mt-5 flex w-full flex-1 flex-col justify-between break-all rounded-md border  bg-white focus:border focus:border-black md:px-3"
        style={{ height: rightHeight || 'auto' }}
      >
        <div
          className="editable-content flex w-full flex-col gap-2 overflow-y-scroll break-normal rounded-md border-2 border-transparent p-2 pr-2 text-sm text-v5-neutral-500 md:text-base"
          contentEditable={true}
          ref={contentRef}
          onInput={handleContentChange}
        >
          <RawHTML>{savedContent}</RawHTML>
        </div>
        <hr />
        <div className="mt-2 flex w-full justify-between md:mt-0">
          <button
            className="mb-2 ml-2 mt-2 self-end rounded-md border border-v5-yellow-200 bg-v5-yellow-200  px-3 py-2 font-manrope text-sm font-semibold text-black shadow-v5-yellow-200/50 hover:shadow-sm md:ml-0 md:text-base md:font-medium"
            onClick={downloadCertificate}
          >
            <FontAwesomeIcon icon={faArrowDownToLine} />
            <span className="ml-1">Download</span>
          </button>
          <button
            className="mb-2 mr-2 mt-2 self-end rounded-md border border-v5-yellow-200 bg-v5-yellow-200 px-3 py-2 font-manrope text-sm font-semibold text-black shadow-v5-yellow-200/50 hover:shadow-sm hover:shadow-v5-yellow-100/50 md:mr-0 md:text-base md:font-medium"
            onClick={shareOnLinkedInV2}
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
            <span className="ml-1">LinkedIn</span>
          </button>
        </div>
      </div>
    </div>
  );
}
